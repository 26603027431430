.queue
	position: absolute
	top: .625rem
	right: .625rem
	display: flex
	flex-direction: column
	align-items: flex-end
	font-size: 0.8em
	gap: 0.5em

.items
	display: flex
	flex-direction: row-reverse

.photo
	display: flex
	align-items: center
	justify-content: center
	border-radius: 50%
	overflow: hidden
	width: 2.25rem
	aspect-ratio: 1
	position: relative
	font-size: 1.625rem
	border: 2px solid var(--global-colors-offWhite-700)
	background: var(--global-colors-light)

	& + &
		margin-right: -1.25rem
