@use '../app/styles/vars'

.wrapper
	display: flex
	flex-wrap: wrap
	border-radius: 5px

	&>*
		flex-basis: 100%
.switch_wrapper
	padding-bottom: 20px
	margin-bottom: 20px
	border-bottom: 2px solid grey
.button
	padding: 10px
	margin: 10px
	font-size: 15px
	font-weight: 100
	color: grey
	background: white
	border: 2px solid grey
	border-radius: 7px
.tileSpacing
	margin-bottom: 10px
.deadlineLine
	border-bottom: 2px dashed rgba(33,33,33,0.5)
	margin: 10px (-1 * vars.$pageSpacing)

.monthDeadline,
.deadlineTicker
	flex-grow: 1
	position: sticky
	top: 0
	background-color: #edeae5
	margin: 0 (-1 * vars.$pageSpacing) 20px
.monthDeadline
	flex-basis: 90px
	z-index: 1
	padding: .625rem vars.$pageSpacing .8125rem

	&::after
		content: ''
		height: .0625rem
		position: absolute
		top: 100%
		left: 0
		right: 0
		background-color: #000000

.deadlineTicker
	padding: 8px 10px
	flex-basis: 50%
	z-index: 2
	display: flex
	justify-items: flex-end
	justify-content: flex-end

.notesTitle
	color: var(--global-colors-dark)
	margin: 0

.notes
	display: grid
	gap: 1em
	margin-block: 1em
