@use '../app/styles/vars'

$tileHeight: 4.6875rem

.wrapper
	padding: 1px
	border-radius: 5px
	justify-self: center
	display: grid
	grid-template-columns: 50px 1fr
	min-height: $tileHeight

.date
	display: grid
	grid-template-columns: 1fr
	font-weight: 300
	border-radius: 20px

.weekdayDateWrapper
	height: $tileHeight
	color: #878787
	padding-top: 14px
	text-transform: uppercase
	font-weight: 800
	font-size: 18px
	padding: 0
	cursor: default
	display: flex
	align-items: center
	justify-content: center

.weekdayDateWrapper:not(:disabled)
	cursor: pointer

	&:hover, &:focus
		color: var(--global-colors-dark)
		outline: none

	.weekdayDate
		position: relative

		&::after
			content: ""
			background-color: var(--global-colors-red-600)
			width: .5rem
			height: .5rem
			position: absolute
			left: 100%
			bottom: calc(100% - 0.25rem)
			display: block
			border-radius: 50%

.modalContent
	padding: 1.5em
	min-height: 12.5rem

.notes
	display: grid
	gap: 1em

.notesTitle
	margin-block: 0 1em

.shift
	display: grid
	grid-template-columns: 1fr

.assignButton:active
	background: linear-gradient(240deg, rgba(255,255,255,0.5) 69%, rgba(0,0,0,0.05) 100%)

.buttonContent
	height: 26px
	width: 26px
	color: #EDEAE5
