@use '../app/styles/vars'
.wrapper
	min-height: 100vh
	max-width: 768px
	width: 100%
	background: #EDEAE5
	margin: 0 auto
	@media (vars.$minDesktopWidth)
		padding: 0 vars.$desktopPageSpacing

.main
	padding: 0 vars.$pageSpacing

.warning
	margin-top: 3rem
