@use '../app/styles/vars'

.assignButtonWrapper
	all: unset
	cursor: pointer
	height: 75px
	background: #ffffff
	border: 2px solid #D7D4CF
	display: flex
	align-items: center
	flex-grow: 1
	transition-property: margin-left
	transition-duration: 0.3s
	position: relative
	&.shake
		animation: shake 0.7s
	&.openDynamicActive
		margin-left: -1 * vars.$selectExpand
		//border: none
	// &.openSubstitution
	// 	//@TODO replace placeholder style
	// 	background: rgba(255,0,0,0.1)
	// &.openOrSubstitute
	// 	background: rgba(0,0,255,0.1)
	// &.assignedSubstitute
	// 	background: rgba(255,255,0,0.1)
	// &.substitued
	// 	background: rgba(0,255,255,0.1)
	// &.substituting
	// 	background: rgba(255,0,255,0.1)
	&[disabled]
		cursor: auto
	&.confirmed
		background-color: #daecd8
		border-color: #bbdaa9
		color: #2ea271
	&.disabled
		background-color: #eaeaea
		border-color: transparent
		color: #c8c5c1
	&.inQueue,
	&.inQueueDynamic,
	&.assigning,
	&.pendingInQueue
		border-color: #8d8c8b
		background-color: #E6E5E3
		color: #333333
	&.assigning,
	&.unassigning,
	&.pendingInQueue
		opacity: 0.5
	&:first-child
		border-top-left-radius: 20px
		border-top-right-radius: 20px
		border-bottom-width: 1px
	&:only-child
		border-bottom-width: 2px
	&:nth-child(1n+2)
		border-top-width: 1px
		border-bottom-width: 1px
	&:last-child
		border-bottom-left-radius: 20px
		border-bottom-right-radius: 20px
		border-bottom-width: 2px

.assignButton
	border: 2px solid #d7d4cf
	border-radius: 50%
	background: #ffffff
	height: 56px
	min-width: 56px
	margin-left: 11px
	.confirmed &
		border-color: #2ea271
		background-color: #daecd8
	.disabled &
		border-color: transparent
		background: none
	.assigning &,
	.inQueueDynamic &,
	.inQueue &
		border-color: #333333
		background: none
	.openDynamicActive &,
	.pendingInQueue &
		border-color: #333333
		background: none
.nothing
	height: 30px
	width: 30px
.cross
	transform: rotate(45deg)
	height: 30px
	width: 30px
	margin-top: 11px
	margin-left: 11px
	stroke: #d6d6d6
.closeDynamicCross
	margin-left: 5px
	margin-top: -1px
.tick
	margin-left: 17px
	margin-top: 19px
	height: 10px
	width: 19px
	transform: rotate(-50deg)
	border-left: 2px solid #2ea271
	border-bottom: 2px solid #2ea271

.substituteIcon,
.openIcon
	width: 100%
	aspect-ratio: 1
	display: flex
	align-items: center
	justify-content: center
	font-size: 2em

.openIcon
	color: #d7d4cf

.tickBlack
	border-color: #333333
.finger
	margin: 11px 0 0 14px
.startEndTime,
.startEndTimeDynamic
	align-self: flex-start
	margin-right: 10px
	font-weight: 600
.startEndTimeDynamic
	display: flex
.buttonText
	display: grid
	grid-template-rows: 1fr 2fr
	margin-left: 10px
	margin-top: 10px
.statusText
	margin-top: 8px
	font-size: 14px
.clockOutside
	border-radius: 50%
	border: 2px solid #333333
	width: 16px
	height: 16px
.clockInside
	margin-left: 5px
	height: 8px
	width: 7px
	border-left: 2px solid #333333
	border-bottom: 2px solid #333333
	border-bottom-left-radius: 2px
.clockTextAlign
	display: flex
	gap: 7px
.unassignConfirm
	border-top-right-radius: 20px
	border-bottom-right-radius: 20px
	width: 50px
	position: absolute
	top: 0
	bottom: 0
	right: 0
	display: flex
	align-items: center
	justify-content: center
	background-color: #D43C3C
	height: calc(100% + 4px)
	transform: translate(2px, -2px)
	animation: slide 0.2s
	z-index: 1
.unassignCross
	transform: rotate(45deg)
	margin-top: 5px
	height: 30px
	width: 30px
	stroke: #FBFBFB
.timeSlider
	flex-grow: 1
	//animation: display
	//animation-duration: 0.5s
	display: grid
	grid-template-columns: 100%
.sliderStyle,
.closeDynamicTime
	grid-row: 1
	grid-column: 1
.sliderStyle
	height: 78px
	border-top-right-radius: 20px
	border-bottom-right-radius: 20px
	overflow: hidden
.closeDynamicTime
	background: #ffffff
	justify-self: right
	border: 1px solid #333333
	border-radius: 50%
	height: 22px
	width: 22px
	margin-top: -5px
	margin-right: -5px
	z-index: 2

@keyframes slide
	0%
		transform: translate(1.25rem, -2px)
		opacity: 0
	100%
		transform: translate(2px, -2px)
		opacity: 1

@keyframes shake
	0%
		transform: translateX(0)
	12.5%
		transform: translateX(7px)
	25%
		transform: translateX(-7px)
	37.5%
		transform: translateX(7px)
	50%
		transform: translateX(0)
	62.5%
		transform: translateX(3px)
	75%
		transform: translateX(-3px)
	87.5%
		transform: translateX(3px)
	100%
		transform: translateX(0)
