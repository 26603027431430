@use '../app/styles/vars'

.wrapper
	text-transform: uppercase
	color: #333333
	padding-top: 8px
	font-size: 19px
	font-weight: 700
	width: 90px
	display: flex
	gap: 0.5em
	align-items: center

	@media (vars.$minDesktopWidth)
		font-size: 36px

.previous
	transform: scaleX(-1)
