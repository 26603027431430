.wrapper
	border: 2px solid #333333
	border-radius: 36px
	color: #333333
	font-weight: 800
	min-height: 38px
	text-align: center
	width: max-content
	padding: 7px 13px 0 11px
	justify-self: right
	padding-top: 0
	&.wrapperBlack
		border: 2px solid #333333
		color: #333333
	&.wrapperRed
		border: 2px solid #b60505
		background: #EFD3CE
		color: #b60505
	&.wrapperClosed
		border: 2px solid #908f8c
		color: #908f8c
