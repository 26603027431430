.wrapper
	position: relative
	background-color: #edeae5
.top
	height: 3.4375rem
	width: 100%
	display: flex
	gap: 1em
	align-items: center
	position: absolute
	top: 0
	left: 0
	right: 0
.name
	margin: 0
	color: var(--global-colors-dark)

.filters
	position: sticky
	top: 0
	left: 0
	right: 0
	z-index: 3
	display: flex
	justify-content: flex-end
	min-height: 3.4375rem
	pointer-events: none

.filtersIn
	display: flex
	gap: 1.25rem
	pointer-events: all
