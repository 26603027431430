.title
	margin-top: 0
	margin-bottom: .5em
	text-align: center
	color: var(--global-colors-dark)

.content
	padding: 20px

.buttons
	display: grid
	gap: .625rem
	margin-top: 2.5rem

.button
	width: 100%
	display: grid

.remove
	margin-top: 1.5rem
