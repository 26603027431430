.wrapper
	margin-right: 5px
	margin-left: 50px
	display: grid
	grid-template-columns: 10px 1fr 10px
	touch-action: none
	margin-top: -38px
.slider1
	background: #e6e5e3
.slider2
	background: white
.slider1,
.slider2
	position: relative
	width: 100%
	height: 77px
	padding: 10px
	transform: translate(calc((100% - 20px) * var(--TimeSlider-offset)),50%)
.slider2
	display: grid
	grid-column: 2
	grid-template-columns: 10px 1fr
	transform: translate(calc(100% * var(--TimeSlider-offset)),-50%)
.slider1
	margin-left: -65px
	width: calc(100% + 20px)
.slider2
	margin-left: -40px
.displayTime1,
.displayTime2
	transform: translate(-10px, 15px)
	color: #333333
	font-weight: 800
	height: 25px
	padding-left: 6px
	width: 50px
	border-radius: 5px
.displayTime1
	margin-top: 50px
	margin-left: -45px
	z-index: 2
	pointer-events: none
.displayTime2
	transform: translate(-50px,-12px)
	pointer-events: none
.handle1,
.handle2
	border-radius: 2px
	width: 4px
	height: 25px
	background: #ffffff
	transform: translate(5px,15px)
.handle2
	transform: translate(21px,15px)
.greyBox
	background: #e6e5e3
	width: 650%
	height: 76px
	margin-left: -15px
	margin-top: -10px
	pointer-events: none
