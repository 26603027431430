.wrapper
	display: flex
	justify-content: space-between
	padding: 0.5rem 1rem
	border: .0625rem solid var(--global-colors-gray-500)
	border-radius: 0.5em
	position: relative

.text
	margin: 0

	strong
		color: var(--global-colors-dark)

.remove
	visibility: hidden
	display: flex
	align-items: center
	transition-property: color
	transition-duration: 0.3s
	
	.wrapper:hover &
		visibility: visible

	&:hover
		color: var(--global-colors-dark)
		stroke-width: 2
