.title
	margin-top: 0
	margin-bottom: .5em
	text-align: center
	color: var(--global-colors-dark)

.content
	padding: 20px

.buttons
	display: grid
	grid-template-columns: 1fr 1fr
	gap: .625rem
	margin-top: 2.5rem
